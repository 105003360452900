export default function menu(drawer) {
    
    //  ドロワー
    var state = false;
    var scrollpos;
    $(".drawer-btn").on("click", function() {
        if (state == false) {
            scrollpos = $(window).scrollTop();
            $('body').addClass('scroll-prevent').css({'top': -scrollpos});
            $(this).addClass('active');
            $('.drawer-modal').addClass('open');
            $('.drawer-overlay').addClass('open');
            state = true;
        } else {
            $(this).removeClass('active');
            $('body').removeClass('scroll-prevent').css({'top': 0});
            window.scrollTo( 0 , scrollpos );
            $('.drawer-modal').removeClass('open');
            $('.drawer-overlay').removeClass('open');
            state = false;
        }
    });

    $('.close, .drawer-overlay').on('click', function() {
        $('.menu-trigger').click();
    });
}
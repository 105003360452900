export default function menu(tab) {

    // タブ切り替え
    jQuery(function($){
        var position = $('#target').offset().top;
        $('.tabBtn').click(function(){
            $('.tabBtn').removeClass('active');
            $('.show').removeClass('show');
            // クリックしたタブからインデックス番号を取得
            let index = $(this).index();
            const order = index;
            // クリックしたタブと同じインデックス番号をもつコンテンツに.activeを付与
            $('#js-overtab1 .tabBtn').eq(index).addClass('active');
            $(`#js-overtab2 .tabBtn`).eq(index).addClass('active');
            // クリックしたタブ（＋1）と同じインデックス番号をもつコンテンツを表示
            $('.tabList').eq(order).addClass('show');

            // クリックしたら#targetまで移動
            $(window).scrollTop(position);

            // 上下のタブを連動させる
            // const syncTab = new TabList('.business-tab__item', '.business-block');
            // syncTab.fn();
        });
    });
}
export default function menu(example) {

    // タブ切り替え
    jQuery(function($){
        var position = $('#target').offset().top;
        $('.examleSide-tabmenu-list').click(function(){
            $('.active').removeClass('active');
            $(this).addClass('active');
            $('.example-show').removeClass('example-show');
            // クリックしたタブからインデックス番号を取得
            let index = $(this).index();
            const order = index + 1;
            // クリックしたタブと同じインデックス番号をもつコンテンツを表示
            $('.example-block').eq(order).addClass('example-show');

            // クリックしたら#targetまで移動
            $(window).scrollTop(position);
        });
        $('.example-top-container-box').click(function(){
            if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
                $(".example-sidebar__message").show();
                // 一定時間後に非表示
                setTimeout(function() {
                    $('.example-sidebar__message').fadeOut(1000);
                }, 4000);
            }
            $('.example-show').removeClass('example-show');
            // クリックしたタブからインデックス番号を取得
            let index = $(this).index();
            const order = index + 1;
            // クリックしたタブと同じインデックス番号をもつコンテンツに.activeを付与
            $('.examleSide-tabmenu-list').eq(index).addClass('active');
            // クリックしたタブ（＋1）と同じインデックス番号をもつコンテンツを表示
            $('.example-block').eq(order).addClass('example-show');
            // 実績トップページ以外が表示されている場合はサイドバーを表示する
            const tabTop = document.getElementsByClassName('example-block--top');
            console.log(tabTop);
            if(tabTop.className != 'example-show'){
                $('.example-sidebar').show();
            }

            // クリックしたら#targetまで移動
            $(window).scrollTop(position);
        });
    });

    //  SPタブ
    let state = false;
    let scrollpos;
    $(".example-open__btn").on("click", function() {
        $(".example-sidebar__message").fadeOut();
        if(state == false) {
        scrollpos = $(window).scrollTop();
        $('body').addClass('scroll-prevent').css({'top': -scrollpos});
        $('.example-sidebar').addClass('example-open');
        $('.example-overlay').addClass('example-open');
        state = true;
        } else {
        $('body').removeClass('scroll-prevent').css({'top': 0});
            window.scrollTo( 0 , scrollpos );
        $('.example-sidebar').removeClass('example-open');
        $('.example-overlay').removeClass('example-open');
        state = false;
        }
    });
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
        $('.examleSide-tabmenu-list, .example-overlay').on('click', function(){
            $('.example-open__btn').click();
        });
    }
}
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);


export default function menu(topAnimation) {
    // TOP アニメーション
    var controller1 = new ScrollMagic.Controller();

    var aniBusiness = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
        // SP
        aniBusiness.to('.area--business .area-visual__overlay', 0.7, {
            right: "-100%",
            ease: Power0.easeNone
        })
        .set('.area--business .area-visual__overlay', {
            display: "none",
        })
        .staggerFrom('.area--business .area-box', 0.8, {
            opacity: 0,
            transform: "translateX(-20px)",
        }, 0.1, '-=0.3');
    }else {
        // PC
        aniBusiness.to('.area--business .area-visual__overlay', 1.2, {
            right: "-100%",
            ease: Power1.easeIn
        })
        .set('.area--business .area-visual__overlay', {
            display: "none",
        })
        .from('.area--business .sankaku', 1.5, {
            left: "-100%"
        }, '-=1')
        .staggerFrom('.area--business .area-box', 1.2, {
            opacity: 0,
            transform: "translateX(-50px)",
        }, 0.2, '-=0.2');
    }



    var aniRepro = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
        aniRepro.to('.area--repro .area-visual__overlay', 0.7, {
            right: "-100%",
            ease: Power0.easeNone
        })
        .set('.area--repro .area-visual__overlay', {
            display: "none",
        })
        .staggerFrom('.area--repro .area-box', 0.8, {
            opacity: 0,
            transform: "translateX(-20px)",
        }, 0.1, '-=0.3');

    } else {
        aniRepro.to('.area--repro .area-visual__overlay', 1.2, {
            left: "-100%",
            ease: Power1.easeIn
        })
        .set('.area--repro .area-visual__overlay', {
            display: "none",
        })
        .from('.area--repro .sankaku', 1.5, {
            right: "-100%"
        }, '-=1')
        .staggerFrom('.area--repro .area-box', 1.2, {
            opacity: 0,
            transform: "translateX(50px)",
        }, 0.2, '-=0.2');
    }


    var aniSystem = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
        aniSystem.to('.area--system .area-visual__overlay', 0.7, {
            right: "-100%",
            ease: Power1.easeIn
        })
        .set('.area--system .area-visual__overlay', {
            display: "none",
        })
        .staggerFrom('.area--system .area-box', 0.8, {
            opacity: 0,
            transform: "translateX(-20px)",
        }, 0.1, '-=0.3');

    } else {
        aniSystem.to('.area--system .area-visual__overlay', 1.2, {
            right: "-100%",
            ease: Power1.easeIn
        })
        .set('.area--system .area-visual__overlay', {
            display: "none",
        })
        .from('.area--system .sankaku', 1.5, {
                left: "-100%"
            }, '-=1')
        .staggerFrom('.area--system .area-box', 1.2, {
            opacity: 0,
            transform: "translateX(-50px)",
        }, 0.2, '-=0.2');
    }


    var aniAbout = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
        aniAbout.to('.top-column .column-visual__overlay', 0.7, {
            right: "-100%",
            ease: Power1.easeIn
        })
        .set('.top-column .column-visual__overlay', {
            display: "none",
        })
        .staggerFrom('.top-column .column-box', 0.7, {
            opacity: 0,
            transform: "translateX(-20px)",
        });

    } else {
        aniAbout.to('.top-column .column-visual__overlay', 1.2, {
            right: "-100%",
            ease: Power1.easeIn
        })
        .set('.top-column .column-visual__overlay', {
            display: "none",
        })
        .staggerFrom('.top-column .column-box', 1.2, {
            opacity: 0,
            transform: "translateX(-50px)",
        });
    }


    var aniRecruit = new TimelineMax();
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
        aniRecruit.from('.top-recruit .top-recruit__title', 0.5, {
            opacity: 0,
            transform: "translateY(30px)",
        })
        aniRecruit.from('.top-recruit .top-recruit__comment', 0.5, {
            opacity: 0,
            transform: "translateY(30px)",
        }, '-=0.1')
        .staggerFrom('.top-recruit .top-recruit-list__item', 0.5, {
            opacity: 0,
            transform: "translateY(30px)",
        }, 0.1, '-=0.1');

    } else {
        aniRecruit.from('.top-recruit .top-recruit__title', 0.7, {
            opacity: 0,
            transform: "translateY(30px)",
        })
        aniRecruit.from('.top-recruit .top-recruit__comment', 0.7, {
            opacity: 0,
            transform: "translateY(30px)",
        })
        .staggerFrom('.top-recruit .top-recruit-list__item', 0.5, {
            opacity: 0,
            transform: "translateY(30px)",
            ease: Power0.easeNone
        }, 0.2, '-=0.2');
    }



    var scene1 = new ScrollMagic.Scene({
        triggerElement: ".area--business",
        reverse: false
    })
    .setTween(aniBusiness)
    .addTo(controller1);

    var scene2 = new ScrollMagic.Scene({
        triggerElement: ".area--repro",
        triggerHook: 1,
        reverse: false
    })
    .setTween(aniRepro)
    .addTo(controller1);

    var scene3 = new ScrollMagic.Scene({
        triggerElement: ".area--system",
        triggerHook: 1,
        reverse: false
    })
    .setTween(aniSystem)
    .addTo(controller1);

    var scene4 = new ScrollMagic.Scene({
        triggerElement: ".top-column",
        triggerHook: 1,
        reverse: false
    })
    .setTween(aniAbout)
    .addTo(controller1);

    var scene5 = new ScrollMagic.Scene({
        triggerElement: ".top-recruit",
        triggerHook: 1,
        reverse: false
    })
    .setTween(aniRecruit)
    .addTo(controller1);

    }
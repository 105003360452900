import "@babel/polyfill";

/* Modules
==================== */

// topアニメーションjs
import topAnimation from "./modules/topAnimation";
topAnimation();

// gnav ドロップダウン
import dropDown from "./modules/dropDown";
dropDown();

// ドロワー
import drawer from "./modules/drawer";
drawer();

// ページトップ
import pagetop from "./modules/pagetop";
pagetop();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// タブ切り替え
import tab from "./modules/tab";
tab();

// 実績ページjs
import example from "./modules/example";
example();



/* Script
==================== */
objectFitImages();